import axios from "axios";
import Vue from "vue";

// const prefix="/u/";														//静态网页访问前缀，因为安全要求不允许使用默认"/"根路径访问系统
const prefix="";															//静态网页访问前缀，因为安全要求不允许使用默认"/"根路径访问系统
// axios.defaults.baseURL = 'http://127.0.0.1:8080';							//本机开发后端ip
axios.defaults.baseURL = 'https://crmapi.dayuanxiaoyuan.com';						//生产环境后端ip，nginx会根据请求路径"/hr/backend"判断是否转发到后台服务器还是直接访问静态网页资源
axios.defaults.headers.common['token'] = localStorage.getItem("token"); 	//注意此处的token赋值流程是 登录成功返回token-> 调用store/index.js/setToken(token)
axios.defaults.headers.post['Content-Type'] = 'application/json';			//其它格式'application/x-www-form-urlencoded' 'multipart/form-data'

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
	// 剔除get请求url中携带的空参数
	for(var k in config.params)	{
		if(config.params[k]===""){
			delete config.params[k];
		}
	}
	// 剔除post方法提交的空白字段,对于put、patch修改方法放行。
	if(config.headers.post["Content-Type"]=="application/json"){
		deleteEmptyField(config.data);
	}
    return config;
}, function (error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});


axios.interceptors.response.use(function (response) {	// 添加响应拦截器
    // 对响应数据做点什么
    return response;
}, function (error) {
  if (error.response) {
	    Vue.prototype.$message({type:"error",message:error.response.data.message});
	if(error.response.data.code==401){	//长时间未操作会话失效，那么就跳转到登录页面重新登录
		//跳转页面太快会 导致messge提示框显示不出来，延迟500毫秒
		setInterval(()=>{
			// store.commit("clearToken");
			location.href=prefix+"index.html";
		},500)
		
	}else{
		console.error("error.response.data",error.response.data);
		console.error("error.response.status",error.response.status);
		console.error("error.response.headers",error.response.headers);
	}
  } else {
	console.error("Error",error);
	Vue.prototype.$message({type:"error",message:error.message});
  }
	// store.commit("hideLoading");
	return Promise.reject(error);
});

// 递归剔除post方法提交的空白字段
function deleteEmptyField(obj){
	if(Array.isArray(obj)){
		for (var i = 0; i < obj.length; i++) {
			deleteEmptyField(obj[i]);
		}
	}else if(typeof obj == "object"){
		for(var k in obj)	{
			if(typeof obj[k] == "object"){
				deleteEmptyField(obj[k])
			}else if(typeof obj[k] == "string" && obj[k]===""){
				delete obj[k];
			}
		}
	}
}



//检查是否是第一次登录，如果是就返回动态口令密钥二维码与动态口令输入框
const preLogin = (username) => axios.get("/open/preLogin?username="+username);

//登录
const login = (username, password,code) => {
	const params = new URLSearchParams();
	params.append('username', username);
	params.append('password', password);
	params.append('code', code);
	return axios.post("/login", params);
};

//退出
const logout = () => axios.post("/logout");

//修改密码
const changePassword = (username,data)=>{
	const params = new URLSearchParams();
	params.append('username', data.username);
	params.append('oldPassword', data.oldPassword);
	params.append('newPassword', data.newPassword);
	params.append('newPassword2', data.newPassword2);
	return axios.put("/users/"+username,params);
}

//getUsers
const getUsers = (username,nickname,status,offset,limit,deptId) => axios.get("/users",{params:{username:username,nickname:nickname,status:status,offset:offset,limit:limit,deptId:deptId}});
		
//getUser
const getUser = (id)=>axios.get("/users/"+id);

//addUser
const addUser = (user)=>axios.post("/users",user);		

//updateUser
const updateUser = (user)=>axios.put("/users",user);

//deleteUser
const deleteUser = (id)=>axios.delete("/users/"+id);

//updateHeadImg
const updateHeadImg = (url)=>axios.put("/users?headImgUrl="+url);

//getOwnInfo
const getOwnInfo = ()=>axios.get("/users/current");

//getOwnRoles
const getOwnRoles = ()=>axios.get("/roles/own");

//getAllRoles
const getAllRoles = ()=> axios.get("/roles/all");

const getRoleByUser=(id)=>axios.get("/roles?userId="+id)

//
const getPermissionIsDirectory = ()=>axios.get("/permissions/parents");

//添加权限
const addPermission = (permission)=>axios.post("/permissions",permission);

//获取菜单（权限表过滤产生），渲染成aside侧边栏菜单
const getMenus = () => axios.get("/permissions/current");

//获取当前用户的权限
const getPermissionByUser = ()=>axios.get("/permissions/owns");

//查询权限表所有数据
const permissions = () => axios.get("/permissions");

//查询指定id权限数据
const permissionsById = (id) => axios.get("/permissions/"+id);

//deletePermission
const deletePermission =(id)=> axios.delete("/permissions/"+id);

//updatePermission
const updatePermission = (permission) => axios.put("/permissions/",permission);

//查询当前用户拥有的权限列表
const getAllPermission = ()=>axios.get("/permissions/all");

//查询角色拥有的权限
const getPermissionByRoleId = (roleId)=>axios.get("/permissions?roleId=" + roleId);

//getRoles
const getRoles = (name,offset,limit)=> axios.get("/roles",{params:{name:name,offset:offset,limit:limit}});

//getRole
const getRole = (id)=>axios.get("/roles/"+id);

//addRole
const addRole = (role) => axios.post("/roles",role);

//deleteRole
const deleteRole = (id) => axios.delete("/roles/"+id);

//getFiles
const getFiles = (offset,limit,originalName)=>axios.get("/files/list",{params:{offset:offset,limit:limit,orderBy:"order by createTime desc",originalName:originalName}});

//getFile
const getFile = (name)=>axios.get("/files",{params:{name}});

//deleteFile
const deleteFile = (id)=>axios.delete("/files/"+id);

//uploadFile
const uploadFile = ()=>axios.post("/files");

//uploadFile 同时上传多个文件
const uploadFiles = (formData)=>axios.post("/files/multipart",formData);

//预览代码结构
const previewCode = (tableName)=>axios.get("/generate?tableName="+tableName);

//生成代码
const generateCode = (form)=>axios.post("/generate",form);

//addNotice
const addNotice = (notice) => axios.post("/notices",notice);

//获取已发布的noticee并标记为已读，未发布的草稿不会被查询到
const getNoticeAndRead = (id)=>axios.get("/notices?id="+id);

//getNotice
const getNotice = (id)=>axios.get("/notices/"+id);

//getNotices
const getNotices = (title,status,beginTime,endTime,offset,limit)=> axios.get("/notices",{params:{title:title,status:status,beginTime:beginTime,endTime:endTime,offset:offset,limit:limit}});

//deleteNotice
const deleteNotice = (id)=>axios.delete("/notices/"+id);

//updateNotice
const updateNotice = (notice) => axios.put("/notices",notice);

//getUnreadNoticeCount
const getUnreadNoticeCount = ()=> axios.get("/notices/count-unread");

//getHasPublishedNotices
const getHasPublishedNotices = (title, isRead,beginTime, endTime, offset,limit)=> axios.get("/notices/published",{params:{title:title,isRead:isRead,beginTime:beginTime,endTime:endTime,offset:offset,limit:limit}});

//getLogs
const getLogs = (nickname,status,beginTime,endTime,offset,limit) => axios.get("/logs",{params:{nickname:nickname,flag:status,beginTime:beginTime,endTime:endTime,offset:offset,limit:limit}});

//getMails
const getMail = (id)=>axios.get("/mails/"+id);

//getMails
const getMails = (subject,beginTime,endTime,offset,limit)=> axios.get("/mails",{params:{subject:subject,beginTime:beginTime,endTime:endTime,offset:offset,limit:limit}});

//addMail
const addMail = (mail)=>axios.post("/mails",mail);

//getMailReceiver
const getMailReceiver = (id)=>axios.get("/mails/"+id+"/to");

//checkCron
const checkCron = (cron)=>axios.get("/jobs?cron="+cron);

//initBeanNames
const initBeanNames = ()=> axios.get("/jobs/beans");

//showMethods
const showMethods = (val)=>axios.get("/jobs/beans/" + val,);
				
//addJob	job=定时任务
const addJob = (job)=>axios.post("/jobs",job);

//updateJob	
const updateJob = (job)=>axios.put("/jobs",job);

//getJob
const getJob = (id)=>axios.get("/jobs/"+id);

//getJobs
const getJobs = (description,status,offset,limit)=>axios.get("/jobs",{params:{description:description,status:status,offset:offset,limit:limit}});

//deleteJob
const deleteJob = (id)=>axios.delete("/jobs/"+id);

//查询下拉输入框以及单选框复选框的各种常量值，方法内实现了本地缓存功能
const getDict = (type,buf,sortFunction) => {
	var cache = sessionStorage[type];
	if (cache == null || cache == "") {
		return axios.get('/dicts?type=' + type).then(res=>{
			if(sortFunction){ res.data.sort(sortFunction)}
			res.data.forEach(item=>buf.push(item));
			sessionStorage[type] = JSON.stringify(res.data);
		});
	}else{
		JSON.parse(cache).forEach(item=>{
			buf.push(item)
		});
		return new Promise(function(resolve,reject){
			resolve(buf);
		});
	}
}

const cleanDictCache=(type)=>{ 
	if(Array.isArray(type)){
		type.forEach(item=>sessionStorage.removeItem(item));
	}else{
		sessionStorage.removeItem(type);
	}
}

//getDictById
const getDictById=(id)=>axios.get("/dicts/"+id);

//getDicts
const getDicts= (type,offset,limit)=>axios.get("/dicts/list",{params:{type:type,offset:offset,limit:limit,orderBy:"order by createTime desc"}});

//deleteDict
const deleteDict = (id)=>axios.delete("/dicts/"+id);

//addDict
const addDict=(dict)=>axios.post("/dicts",dict);

//updateDict
const updateDict=(dict)=>axios.put("/dicts",dict);

//getSqlData
const getSqlData=(sql)=>axios.post("/excels/show-datas?sql="+sql);

//
const checkSql = (sql)=>axios.post("/excels/sql-count?sql="+sql);

//exportSql
// const exportSql = (tableName)=>axios.post("/excels",{params:tableName});
// const exportSql = (form)=>{
// 	const params = new URLSearchParams();
// 	params.append('sql', form.sql);
// 	params.append('fileName', form.fileName);
	
// 	return axios.post("/excels",params)
// };



// 查询岗位列表
const listPost=(query)=>axios.get("/system/post/list",{params:query});

// 查询岗位详细
const getPost=(postId)=>axios.get("/system/post/" + postId);

// 删除岗位
const delPost=(postId)=>axios.delete("/system/post/" + postId);

// 新增岗位
const addPost=(data)=>axios.post("/system/post",data);

// 修改岗位
const updatePost=(data)=>axios.put("/system/post",data);

// 导出岗位
const exportPost=(query)=>axios.get("/system/post/export",{params:query});

// 查询部门列表
const listDept = (query) =>axios.get("/system/dept/list",{params: query});

//查询用户所属的部门
const getDeptListByUserId = (userId)=>axios.get("/system/dept/list?userId="+userId)

// 查询部门列表（排除节点）
const listDeptExcludeChild = (deptId) =>axios.get("/system/dept/list/exclude/" + deptId);


// 查询部门详细
const getDept = (deptId) => axios.get("/system/dept/"+deptId); 

// 查询部门下拉树结构
const deptTree = ()=>axios.get("/system/dept/treeselect"); 

// 根据角色ID查询部门树结构
const roleDeptTreeselect = (roleId)=>axios.get("/system/dept/roleDeptTreeselect/" + roleId);
// 新增部门
const addDept = (data)=>axios.post("/system/dept",data);

// 修改部门
const updateDept = (data) =>axios.put("/system/dept",data);

// 删除部门
const delDept = (deptId) =>axios.delete("/system/dept/" + deptId);




export {
	prefix,
	preLogin,
	login,
	logout,
	changePassword,
	getUsers,
	getUser,
	addUser,
	updateUser,
	deleteUser,
	updateHeadImg,
	getOwnInfo,
	getOwnRoles,
	getAllRoles,
	getRoleByUser,
	getPermissionIsDirectory,
	addPermission,
	getMenus,
	getPermissionByUser,
	permissions,
	permissionsById,
	deletePermission,
	updatePermission,
	getAllPermission,
	getPermissionByRoleId,
	getRoles,
	getRole,
	addRole,
	deleteRole,
	getFiles,
	getFile,
	deleteFile,
	uploadFile,
	uploadFiles,
	previewCode,
	generateCode,
	addNotice,
	getNoticeAndRead,
	getNotice,
	getNotices,
	deleteNotice,
	updateNotice,
	getUnreadNoticeCount,
	getHasPublishedNotices,
	getLogs,
	getMail,
	getMails,
	addMail,
	getMailReceiver,
	checkCron,
	initBeanNames,
	showMethods,
	addJob,
	updateJob,
	getJob,
	getJobs,
	deleteJob,
	getDict,
	getDictById,
	getDicts,
	cleanDictCache,
	deleteDict,
	addDict,
	updateDict,
	getSqlData,
	checkSql,
	// exportSql,  //此接口方法使用传统的form表单提交方式处理，不使用ajax
	listDept, 
	getDeptListByUserId,
	getDept, 
	delDept, 
	addDept,
	updateDept,
	listDeptExcludeChild,
	deptTree,
	listPost, getPost, delPost, addPost, updatePost, exportPost
	
	
	
	
};
