//扩展Vue的方法，需要在main.js中使用 Vue.use()注册
// import SearchBar from './components/SearchBar.vue'
// import {logout}	 from './api/base.js'
// import en from './i18n/en.js'
// import zh from './i18n/zh.js'
import axios from 'axios'


const install = function (Vue, opts) 
{
		//权限检查，用于按钮的显示或隐藏，配合 v-if指令使用。
		// Vue.prototype.$hasPermission = function(permission)
		// {
		// 	var permissions=this.$store.state.nav.permissions;
		// 	var index=permissions.findIndex(item=>item==permission);
		// 	console.log("permissions index ",index)
		// 	if(index== -1)
		// 	{
		// 		return false;
		// 	}else
		// 	{
		// 		return true;
		// 	}
		// };
		Vue.prototype.$hasPermission = function(permission)
		{
			var permissionsStr=sessionStorage.getItem("permissions");
			if(! permissionsStr){ return false;}
			var permissions=JSON.parse(permissionsStr);
			var index=permissions.findIndex(item=>item==permission)
			if(index== -1)
			{
				return false;
			}else
			{
				return true;
			}
		};
		
		//权限检查，用于按钮的显示或隐藏，配合 v-if指令使用。
		// Vue.prototype.$hasRole = function(role)
		// {
		// 	var roles=this.$store.state.roles;
		// 	var index=roles.findIndex(item=>item.name==role)
		// 	if(index== -1)
		// 	{
		// 		return false;
		// 	}else
		// 	{
		// 		return true;
		// 	}
		// };
		Vue.prototype.$hasRole = function(role)
		{
			var rolesStr=sessionStorage.getItem("roles");
			if(! rolesStr){ return false;}
			var roles=JSON.parse(rolesStr);
			var index=roles.findIndex(item=>item.name==role)
			if(index== -1)
			{
				return false;
			}else
			{
				return true;
			}
		};
		
		// //配置全局组件搜索行
		// Vue.component("SearchBar",SearchBar);
		
		// //退出登录。 修改密码、修改个人信息、CommonHeader组件共用此方法
		// Vue.prototype.$logout = function(){
		// 	logout().then(res=>{console.log("logout",res)}).catch(res=>{console.log("logout",res)});
		// 	this.$store.commit("clearToken");
		// 	sessionStorage.removeItem("permissions");
		// 	sessionStorage.removeItem("roles");
		// 	// this.$store.commit("clearPermissions");
		// 	// this.$store.commit("clearRoles");
		// 	location.reload();
		// };
		
		// Vue.prototype.$showLoading = function(){
		// 	this.$store.commit("showLoading");
		// };
		
		
		// Vue.prototype.$hideLoading = function(){
		// 	this.$store.commit("hideLoading");
		// };
		
		// //国际化翻译方法
		// Vue.prototype.$t = function(key) {
		// 	var i18n=this.$store.state.i18n;
		// 	var options;
		// 	if(i18n=='en'){
		// 		options=en;
		// 	}else{
		// 		options=zh;
		// 	}
		//   // 获取 `options` 对象的深层属性
		//   // 使用 `key` 作为索引
		//   return key.split('.').reduce((o, i) => { if (o) return o[i] }, options)
		// }
		
		//字典常量值快速查找
		Vue.prototype.$dv = function(list,key) {
			if(Array.isArray(list)){
				return list.find(dict=>dict.k==key)?.val;
			}else{
				return "error";
			}
			list.find()
		}
		//组装服务器上面的静态资源链接
		Vue.prototype.$getHref = function(url) {
			return axios.defaults.baseURL + "/statics" + url + "?token=" + localStorage.getItem("token");
		}
		
}

export default	
{
	install	
}
