import Vue from "vue";
import VueRouter from "vue-router";
import {prefix} from '../api/base.js'

Vue.use(VueRouter);

const routes = [
	// {
	//   path:"/",
	//   component:()=> import("../components/Layout.vue"),
	//   meta:{requiresAuth:true},
	//   children:[
	// 	{
	// 		  path:"/",
	// 		  name:"home",
	// 		  component:()=> import("../views/home/Home.vue"),
	// 		  meta:{requiresAuth:true,title:"首页"}
	// 	}
	//   ]
	// },
	
	// /c/:id /n/:id在2023.7.28号以后就不使用了，暂时留着为了兼容28号以前发出去的链接，将来这俩route会删除
	{path:"/q/:id",name:"Quote",component:()=> import("../views/Quote.vue"), meta:{requiresAuth:false,title:"Quote"}},
	{path:"/c/:id",name:"Receipt1",component:()=> import("../views/Receipt.vue"), meta:{requiresAuth:false,title:"Receipt1"}},
	{path:"/r/:id",name:"Receipt",component:()=> import("../views/Receipt.vue"), meta:{requiresAuth:false,title:"Receipt"}},
	{path:"/n/:id",name:"Tomorrow1",component:()=> import("../views/Tomorrow.vue"), meta:{requiresAuth:false,title:"Tomorrow1"}},
	{path:"/t/:id",name:"Tomorrow",component:()=> import("../views/Tomorrow.vue"), meta:{requiresAuth:false,title:"Tomorrow"}},
	{path:"/calendar/:id",name:"Calendar",component:()=> import("../views/Calendar.vue"), meta:{requiresAuth:false,title:"Calendar"}},
	{path:"/p/:id",name:"Policy",component:()=> import("../views/Policy.vue"), meta:{requiresAuth:false,title:"Policy"}},
	{path:"/thanks",name:"Thanks",component:()=> import("../views/Thanks.vue"), meta:{requiresAuth:false,title:"Thanks"}},
	{path: "*",name:"404",component:()=>import("../views/404.vue")},

];

const router = new VueRouter({
  routes:routes,
  base:prefix
});

export default router;
