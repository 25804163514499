import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import extend from './extend.js'				//扩展的全局方法

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(extend);
new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
